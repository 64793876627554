import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './scss/style.scss';
import './style.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

// Lógica de Títulos Dinâmicos
const usePageTitle = () => {
  const location = useLocation();

  React.useEffect(() => {
    switch (location.pathname) {
      case '/login':
        document.title = 'ADESG';
        break;
      case '/404':
        document.title = 'Erro 404 - Página Não Encontrada';
        break;
      case '/500':
        document.title = 'Erro 500 - Erro Interno do Servidor';
        break;
      default:
        document.title = 'ADESG';
    }
  }, [location]);
};

const AppRoutes = () => {
  usePageTitle(); // Atualiza o título conforme a rota

  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
  );
};

class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <AppRoutes />
      </BrowserRouter>
    );
  }
}

export default App;
